const Colors = {
  primary: '#E4B474',
  primaryDarker: '#926832',
  mainFontColor: 'rgba(255, 255, 255, 1)',
  mutedFontColor: 'rgba(152, 150, 153, 1)',
  white: '#ffffff',
  orange: '#B96F00',
  error: '#F97272',
  green: '#66D012',
  success: '#80d086',
  borderColor: '#b7b7b7',
  background: '#000000',
  backgroundSecondary: '#202020',
  linearGradient: 'linear-gradient(114.02deg, #131312 49.12%, #302F2E 82.45%)',
  positiveBackground: 'rgba(27, 183, 62, 0.25)',
  positiveFont: '#127021',
  negativeBackground: 'rgba(183, 27, 27, 0.25)',
  negativeFont: '#701212',
};

export { Colors };

import React from 'react';
import ReactDOM from 'react-dom';
import { Box, CircularProgress } from '@material-ui/core';
import { Colors } from './common/styles/Colors';

enum Projects {
  Main = 'main',
  Admin = 'admin',
}

let App;

switch (process.env.REACT_APP_BUILD_TARGET) {
  case Projects.Admin:
    App = React.lazy(() => import('./admin/App'));
    break;
  default:
    App = React.lazy(() => import('./App'));
    // App = React.lazy(() => import('./admin/App'));

}

ReactDOM.render(
  <React.StrictMode>
    <React.Suspense
      fallback={
        <Box
          style={{
            margin: 0,
            height: '100vh',
            width: '100vw',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#000000',
            justifyContent: 'center',
          }}
        >
          <CircularProgress color="primary" style={{ color: Colors.primary }} />
        </Box>
      }
    >
      <App />
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById('root'),
);
